import React, { useEffect, useRef, useState } from "react";
import CSVUploader from "../components/CSVUploader.tsx";
import { RefundService } from "../services/RefundService.ts";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import { CiLink } from "react-icons/ci";
import { sleep } from "../utils/utils.ts";
import dayjs from "dayjs";

export const RefundsPage = () => {
  const timeoutRef = useRef(null);
  const [filters, setFilters] = useState({
    pending: true,
    approved: true,
    completed: false,
    rejected: false,
  });
  const [dateFilter, setDateFilter] = useState(null);

  const columns = [
    {
      title: "URL To Check",
      dataIndex: "urlToCheck",
      key: "urlToCheck",
      render: (urlToCheck) => (
        <div>
          {urlToCheck ? (
            <a href={urlToCheck} target="_blank">
              <CiLink size={20} />
            </a>
          ) : null}
        </div>
      ),
    },
    {
      title: "Estado",
      dataIndex: "dgState",
      key: "dgState",
      render: (text, obj) => (
        <div style={{ width: "100px" }}>
          <Select
            value={text}
            onChange={(e) => {
              RefundService.updateRefund(obj._id, {
                ...obj,
                dgState: e,
              }).then((res) => {
                const indexToUpdate = data.findIndex(
                  (item) => item._id === obj._id
                );
                data[indexToUpdate] = res.data;
                setData([...data]);
                notification.success({
                  message: "Actualizado",
                });
              });
            }}
            options={[
              { value: "pending", label: "Pendiente" },
              { value: "approved", label: "Aprobado" },
              { value: "completed", label: "Completado" },
              { value: "rejected", label: "Rechazado" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Notas",
      dataIndex: "dgNotes",
      key: "dgNotes",
      render: (text, obj) => (
        <div style={{ width: "300px" }}>
          <Tooltip title={text}>
            <Input
              defaultValue={text}
              onChange={(e) => {
                const newValue = e.target.value;
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                  RefundService.updateRefund(obj._id, {
                    ...obj,
                    dgNotes: newValue,
                  }).then((res) => {
                    const indexToUpdate = data.findIndex(
                      (item) => item._id === obj._id
                    );
                    data[indexToUpdate] = res.data;
                    setData([...data]);
                    notification.success({
                      message: "Actualizado",
                    });
                  });
                }, 2000); // 5 segundos
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Dias desde",
      dataIndex: "days",
      key: "days",
      render: (days, obj) => {
        const now = dayjs();
        return now.diff(dayjs(obj["approval-date"]), "days");
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approval-date",
      key: "approval-date",
      render: (date) => dayjs(date).format("YYYY/MM/DD"),
    },
    {
      title: "Reimbursement ID",
      dataIndex: "reimbursement-id",
      key: "reimbursement-id",
    },
    {
      title: "Case ID",
      dataIndex: "case-id",
      key: "case-id",
    },
    {
      title: "Amazon Order ID",
      dataIndex: "amazon-order-id",
      key: "amazon-order-id",
    },
    // {
    //   title: "Reason",
    //   dataIndex: "reason",
    //   key: "reason",
    // },
    // {
    //   title: "SKU",
    //   dataIndex: "sku",
    //   key: "sku",
    // },
    // {
    //   title: "FNSKU",
    //   dataIndex: "fnsku",
    //   key: "fnsku",
    // },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (asin) => {
        return <a href={`http://localhost:3000/products/${asin}`}>{asin}</a>;
      },
    },
    // {
    //   title: "Product Name",
    //   dataIndex: "product-name",
    //   key: "product-name",
    //   render: (productName) => <span>{productName.slice(0, 10)}...</span>,
    // },
    // {
    //   title: "Condition",
    //   dataIndex: "condition",
    //   key: "condition",
    // },
    // {
    //   title: "Currency Unit",
    //   dataIndex: "currency-unit",
    //   key: "currency-unit",
    // },
    {
      title: "Amount Per Unit",
      dataIndex: "amount-per-unit",
      key: "amount-per-unit",
    },
    {
      title: "Amount Total",
      dataIndex: "amount-total",
      key: "amount-total",
    },
    {
      title: "Quantity Reimbursed Cash",
      dataIndex: "quantity-reimbursed-cash",
      key: "quantity-reimbursed-cash",
    },
    {
      title: "Quantity Reimbursed Inventory",
      dataIndex: "quantity-reimbursed-inventory",
      key: "quantity-reimbursed-inventory",
    },
    {
      title: "Quantity Reimbursed Total",
      dataIndex: "quantity-reimbursed-total",
      key: "quantity-reimbursed-total",
    },
    {
      title: "Original Reimbursement ID",
      dataIndex: "original-reimbursement-id",
      key: "original-reimbursement-id",
    },
    {
      title: "Original Reimbursement Type",
      dataIndex: "original-reimbursement-type",
      key: "original-reimbursement-type",
    },
  ];

  const [data, setData] = useState([]);

  const handleOnLoaded = (res) => {
    refresh();
  };

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    refresh();
  }, [filters, dateFilter]);

  const refresh = () => {
    RefundService.getRefunds().then((res) => {
      let aux: any = [];
      if (filters.pending) {
        aux = [
          ...aux,
          ...res.data.filter((item) => item.dgState === "pending"),
        ];
      }
      if (filters.approved) {
        aux = [
          ...aux,
          ...res.data.filter((item) => item.dgState === "approved"),
        ];
      }
      if (filters.completed) {
        aux = [
          ...aux,
          ...res.data.filter((item) => item.dgState === "completed"),
        ];
      }
      if (filters.rejected) {
        aux = [
          ...aux,
          ...res.data.filter((item) => item.dgState === "rejected"),
        ];
      }

      if (dateFilter) {
        aux = aux.filter((item) => {
          const itemDate = dayjs(item["approval-date"]);
          const itemDateYear = itemDate.get("year");
          const itemDateMonth = itemDate.get("month");
          const aux = dateFilter.split("-");
          const dateFilterYear = parseInt(aux[0]);
          const dateFilterMonth = parseInt(aux[1]);

          if (
            itemDateYear === dateFilterYear &&
            itemDateMonth === dateFilterMonth - 1
          ) {
            return item;
          }
        });
      }
      setData(aux);
    });
  };

  const handleOpenTabs = async () => {
    const aux = data
      .filter((item) => item.urlToCheck !== null)
      .map((el) => el.urlToCheck);

    for (let i = 0; i < aux.length; i++) {
      await sleep(2000);
      window.open(aux[i], "_blank");
    }
  };

  const handleFilters = (type, e) => {
    setFilters({ ...filters, [type]: e.target.checked });
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Reembolsos</h1>
      </div>
      <p>
        Descarga en csv desde{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://sellercentral.amazon.es/reportcentral/REIMBURSEMENTS/1"
        >
          aquí
        </a>
      </p>
      <div className="dg-item">
        <CSVUploader onLoaded={handleOnLoaded} />
      </div>
      <div className="dg-item">
        <Checkbox
          checked={filters.pending}
          onChange={(e) => handleFilters("pending", e)}
        >
          Pendiente
        </Checkbox>
        <Checkbox
          checked={filters.approved}
          onChange={(e) => handleFilters("approved", e)}
        >
          Aprobado
        </Checkbox>
        <Checkbox
          checked={filters.completed}
          onChange={(e) => handleFilters("completed", e)}
        >
          Completado
        </Checkbox>
        <Checkbox
          checked={filters.rejected}
          onChange={(e) => handleFilters("rejected", e)}
        >
          Rechazado
        </Checkbox>
        <DatePicker
          picker="month"
          onChange={(date, dateString) => setDateFilter(dateString)}
        />
      </div>

      {data.length > 0 && (
        <>
          <div className="dg-item">
            <Button type="primary" onClick={handleOpenTabs}>
              Abrir ({data.filter((item) => item.urlToCheck !== null).length})
              pestañas
            </Button>
          </div>
          <div className="dg-item">
            <Table dataSource={data} columns={columns} pagination={false} />
          </div>
        </>
      )}
    </div>
  );
};
