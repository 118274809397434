import axios from "axios";

export class RefundService {
  static async uploadCSV(file: any) {
    const formData = new FormData();
    formData.append("file", file);

    return await axios.post("/refunds/upload-csv", formData);
  }

  static async getRefunds() {
    return await axios.get("/refunds");
  }

  static async setDgState(orderId: string, dgState: string) {
    return await axios.put(`/refunds/${orderId}/dg-state`, { dgState });
  }

  static async setAmazonTicketUrl(orderId: string, amazonTicketUrl: string) {
    return await axios.put(`/refunds/${orderId}/amazon-ticket-url`, {
      amazonTicketUrl,
    });
  }
  static async updateRefund(id: string, refund) {
    return await axios.put(`/refunds/${id}`, { ...refund });
  }
}
